export const USER_LEVEL = {
  STANDARD: 'STANDARD',
  LIGHT: 'LIGHT',
};

export const MASTER_TYPE = {
  B2B: 'B2B',
  B2C: 'B2C',
};
export const EVENT_TYPE = {
  PROMISE: 'PROMISE',
  DISPUTE: 'DISPUTE',
  DEBT_COLLECTION_DELEGATION: 'DEBT_COLLECTION_DELEGATION',
  FORMAL_NOTICE: 'FORMAL_NOTICE',
  REMINDER: 'REMINDER',
};

export const PLANNED_REMINDER = {
  MEDIA: {
    PHONE: 'PHONE',
    LETTER: 'LETTER',
    EMAIL: 'EMAIL',
  },
  STATUS: {
    CANCELED: 'CANCELLED',
    PLANNED: 'PLANNED',
  },
};

export const ORIGIN = {
  COCKPIT: 'COCKPIT',
  WEB: 'WEB',
};

export const REGEX: any = {
  DATE: {
    FR: new RegExp(
      '^([0-9]|[0-2][0-9]|(3)[0-1])(/)(([0-9]|(0)[0-9])|((1)[0-2]))(/)\\d{4}$',
    ), // => dd/MM/yyyy
    INTERNATIONAL: new RegExp(
      '^\\d{4}(-)(([0-9]|(0)[0-9])|((1)[0-2]))(-)([0-9]|[0-2][0-9]|(3)[0-1])$',
    ), // => yyyy-MM-dd
    US: new RegExp(
      '^(([0-9]|(0)[0-9])|((1)[0-2]))(-)([0-9]|[0-2][0-9]|(3)[0-1])(-)\\d{4}$',
    ), // => MM-dd-yyyy
  },
  FORMAT: {
    FR: 'dd/MM/yyyy',
    INTERNATIONAL: 'yyyy-MM-dd',
    US: 'MM-dd-yyyy',
    DEFAULT: 'yyyyMMdd',
  },
  LABEL: {
    FR: {
      FR: 'jj/mm/aaaa',
      INTERNATIONAL: 'aaaa-mm-jj',
      US: 'mm-jj-aaaa',
      DEFAULT: 'aaaammjj',
    },
    EN: {
      FR: 'dd/mm/yyyy',
      INTERNATIONAL: 'yyyy-mm-dd',
      US: 'mm-dd-yyyy',
      DEFAULT: 'yyyymmdd',
    },
  },
};

export const FILE_TYPE = {
  QUADRA: 'QUADRA',
  OTHERS: 'OTHERS',
};

export const KYC_STATUS = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  KO: 'KO',
  OK: 'OK',
  WARN: 'WARN',
  PARTIAL_OK: 'PARTIAL_OK',
  STARTED: 'STARTED',
  PENDING: 'PENDING',
  PROCESS_ERROR: 'KYC_PROCESS_ERROR',
};
